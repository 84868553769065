<template>
  <div>
    <section class="section">
      <div class="container">
        <service-editor v-bind.sync="service"></service-editor>
        <el-button class="publish" type="primary" @click="publishService">Publish</el-button>
      </div>
    </section>
  </div>
</template>

<script>
import { API, graphqlOperation } from 'aws-amplify'
import { createService } from '@/graphql/mutations'
import { API_TYPE, INPUT_TYPE } from '@/graph-constants'
import ServiceEditor from '@/components/ServiceEditor.vue'

export default {
  name: 'ServiceNew',
  components: {
    ServiceEditor,
  },
  data() {
    return {
      inputId: 0,
      service: {
        title: '',
        summary: '',
        description: '',
        amount: 5,
        inputEndpoint: '',
        statusEndpoint: '',
        apiType: API_TYPE.RESPONSE,
        previews: [],
        inputs: [],
        seoTitle: '',
        seoDescription: '',
        seoImage: '',
      },
    }
  },
  methods: {
    async publishService() {
      // Mutation
      const serviceObject = {
        title: this.service.title,
        amount: Math.round(this.service.amount * 100),
        apiType: this.service.apiType,
        inputEndpoint: {
          action: 'POST',
          url: this.service.inputEndpoint,
        },
      }
      const optionalServiceFields = ['summary', 'description', 'seoTitle', 'seoDescription', 'seoImage']
      optionalServiceFields.forEach((field) => {
        if (this.service[field]) {
          serviceObject[field] = this.service[field]
        }
      })
      if (this.service.apiType === API_TYPE.POLL) {
        serviceObject.statusEndpoint = {
          url: this.service.statusEndpoint,
          action: 'POST',
        }
      }
      if (this.service.previews.length > 0) {
        serviceObject.previews = this.service.previews.map((preview) => ({
          type: preview.type,
          value: preview.value,
          description: preview.description || null,
        }))
      }
      if (this.service.inputs.length > 0) {
        serviceObject.input = this.service.inputs.map((input) => {
          const obj = {
            type: input.type,
            key: input.inputKey,
            label: input.label,
            description: input.description,
            required: input.required,
          }
          // eslint-disable-next-line
          const optionalFields = ['accept', 'placeholder', 'defaultValue', 'selectMultiple', 'selectNone']
          optionalFields.forEach((field) => {
            if (input[field] !== 'undefined' && input[field] !== '') {
              obj[field] = input[field]
            }
          })
          if (input.options) {
            obj.options = input.options.map((opt) => ({
              value: opt.value,
              label: opt.label,
              selectMultple: opt.selectMultple,
              selectNone: opt.selectNone,
              selectCreate: opt.selectCreate,
            }))
          }
          return obj
        })
      }
      const newServiceResponse = await API.graphql(
        graphqlOperation(createService, {
          input: serviceObject,
        })
      )
      const newService = newServiceResponse.data.createService
      this.$router.push(`/service/${newService.id}`)
    },
  },
}
</script>

<style lang="scss" scoped>
.input__prepend-select {
  width: 110px;
}
.publish {
  margin-top: 16px;
}
</style>
